/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Custom Swiper Pagination Styles */
.swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  background: rgba(255, 255, 255, 0.5) !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #ffffff !important;
  width: 16px !important;
  border-radius: 4px !important;
}